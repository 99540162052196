import React, { useRef, useState } from "react";
import "./header.css";
import "./animation.css";
import { Link, useNavigate, useLocation } from "react-router-dom";

// img
import insta from "../../assets/img/instagram.3c71d0ef.svg";
import fb from "../../assets/img/facebook.96b5f919.svg";
import ln from "../../assets/img/linkedin.7abb6faf.svg";
import youtube from "../../assets/img/youtube.bee15f9b.svg";
import wp from "../../assets/img/whatsapp.1f721d0d.svg";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectRef = useRef(null);
  const [navbarOpen, setNavbarOpen] = useState(false); // State to handle navbar visibility

  const handleScrollToSection = (e) => {
    const value = e.target.value;
    if (value === "teklif" || value === "elaqe" || value === "xaricde-tehsil") {
      if (location.pathname !== "/") {
        localStorage.setItem("scrollToSection", value);
        navigate("/");
      } else {
        document.getElementById(value).scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate(value);
    }

    if (selectRef.current) {
      selectRef.current.value = "";
    }

    // Close navbar after selection
    setNavbarOpen(false);
  };

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen); // Toggle navbar state
  };

  const handleLinkClick = () => {
    // Close navbar when clicking on a link
    setNavbarOpen(false);
  };

  return (
    <>
      <div className="container">
        <section id="logo">
          <div className="soc-eco-logo">
            <div className="logo">
              <Link
                to="/"
                onClick={() => {
                  navigate("/");
                  setNavbarOpen(false);
                }}
              >
                econelli<span>.</span>
              </Link>
            </div>
            <div className="social-con defe">
              <a
                href="https://www.instagram.com/econelli.education/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <img src={insta} alt="econelli instagram" />
              </a>
              <a
                href="https://www.facebook.com/econelli/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <img src={fb} alt="econelli facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/econelli/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <img src={ln} alt="econelli linkedin" />
              </a>
              <a
                href="https://www.youtube.com/@Econelli8948"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <img src={youtube} alt="econelli youtube" />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=994516190437&text&type=phone_number&app_absent=0"
                onClick={handleLinkClick}
              >
                <img src={wp} alt="econelli whatsapp vatsap watsap" />
              </a>
            </div>
          </div>

          <div className="animation lo-ani">
            <main className="container-anim anoc">
              <section className="animation">
                <div className="first">
                  <div>FİL - FƏRDİ İNKŞAF LAYİHƏSİ</div>
                </div>
                <div className="second">
                  <div>XARİCDƏ TƏHSİL VƏ YENİ HƏYAT</div>
                </div>
                <div className="third">
                  <div>QLOBAL İMKANLAR VƏ DAHA ÇOXU</div>
                </div>
              </section>
            </main>
          </div>
          <div className="contact anoc">
            <p>
              <a href="tel:+994516190437"> +994 51 619 04 37 </a>
            </p>
            <div className="social-con">
              <a
                href="https://www.instagram.com/econelli.education/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <img src={insta} alt="econelli instagram" />
              </a>
              <a
                href="https://www.facebook.com/econelli/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <img src={fb} alt="econelli facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/econelli/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <img src={ln} alt="econelli linkedin" />
              </a>
              <a
                href="https://www.youtube.com/@Econelli8948"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                <img src={youtube} alt="econelli youtube" />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=994516190437&text&type=phone_number&app_absent=0"
                onClick={handleLinkClick}
              >
                <img src={wp} alt="econelli whatsapp vatsap watsap" />
              </a>
            </div>
          </div>
          <div className="btn anoc">
            <Link
              to="https://forms.gle/tBKLodAkCAPpumQ36"
              onClick={handleLinkClick}
            >
              Xaricdə təhsil
            </Link>
            <Link
              to="https://docs.google.com/forms/d/127_rtsybIAapxqPACeRLtVopV1pHgYaYZcpZs-ljR4c/edit"
              onClick={handleLinkClick}
            >
              Dil kursu
            </Link>
          </div>
          <div className="hamburger" onClick={toggleNavbar}>
            <div className="bar"></div>
            <div className="bar barar"></div>
            <div className="bar"></div>
          </div>
        </section>

        <div className="animation cano">
          <main className="container-anim cano">
            <section className="animation">
              <div className="first">
                <div>FİL - FƏRDİ İNKŞAF LAYİHƏSİ</div>
              </div>
              <div className="second">
                <div>XARİCDƏ TƏHSİL VƏ YENİ HƏYAT</div>
              </div>
              <div className="third">
                <div>QLOBAL İMKANLAR VƏ DAHA ÇOXU</div>
              </div>
            </section>
          </main>
        </div>

        <section id="navbar">
          <nav>
            <div className={`navbar ${navbarOpen ? "open" : ""}`}>
              <Link to="/haqqimizda" onClick={handleLinkClick}>
                Haqqımızda
              </Link>
              <Link to="/fil" onClick={handleLinkClick}>
                FİL
              </Link>
              <select
                name="services"
                id="services"
                onChange={(e) => {
                  handleScrollToSection(e);
                  handleLinkClick(); // Закрытие бургера после выбора
                }}
                ref={selectRef}
              >
                <option value="" disabled selected>
                  Xidmət seçin
                </option>
                <option value="teklif">Təkliflər</option>
                <option value="/general-english">General English</option>
                <option value="/ielts">IELTS</option>
                <option value="/sat">SAT</option>
                <option value="/xaricde-tehsil">Xaricdə Təhsil</option>
                <option value="/abituriyentler-ingilis-dili">
                  Abituriyent üçün İngilis Dili
                </option>
                <option value="/korporativ-ingilis-dili">
                  Korporativ İngilis Dili
                </option>
                <option value="/danishiq-klublar">Danışıq Klubları</option>
                <option value="/etudler">Etüdlər</option>
              </select>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  if (location.pathname !== "/") {
                    localStorage.setItem("scrollToSection", "yardimlarimiz");
                    navigate("/");
                  } else {
                    document
                      .getElementById("yardimlarimiz")
                      .scrollIntoView({ behavior: "smooth" });
                  }
                  handleLinkClick(); // Закрытие бургера после выбора
                }}
              >
                Xaricdə təhsil
              </Link>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  if (location.pathname !== "/") {
                    localStorage.setItem("scrollToSection", "elaqe");
                    navigate("/");
                  } else {
                    document
                      .getElementById("elaqe")
                      .scrollIntoView({ behavior: "smooth" });
                  }
                  handleLinkClick(); // Закрытие бургера после выбора
                }}
              >
                Əlaqə
              </Link>
            </div>
          </nav>
        </section>

        {navbarOpen && (
          <div className="mobile-menu">
            {/* <div className="contact">
              <p>
                <a href="tel:+994516190437" onClick={handleLinkClick}>
                  +994 51 619 04 37
                </a>
              </p>
              <div className="social-con">
                <a href="https://www.instagram.com/econelli.education/" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                  <img src={insta} alt="" />
                </a>
                <a href="https://www.facebook.com/econelli/" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                  <img src={fb} alt="" />
                </a>
                <a href="https://www.linkedin.com/company/econelli/" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                  <img src={ln} alt="" />
                </a>
                <a href="https://www.youtube.com/@Econelli8948" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                  <img src={youtube} alt="" />
                </a>
                <a href="https://api.whatsapp.com/send/?phone=994516190437&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                  <img src={wp} alt="" />
                </a>
              </div>
            </div> */}
            <div className="mobi-tel">
              <div className="btn btn-mobi">
                <Link
                  to="https://forms.gle/tBKLodAkCAPpumQ36"
                  onClick={handleLinkClick}
                >
                  Xaricdə təhsil
                </Link>
                <Link
                  to="https://docs.google.com/forms/d/127_rtsybIAapxqPACeRLtVopV1pHgYaYZcpZs-ljR4c/edit"
                  onClick={handleLinkClick}
                >
                  Dil kursu
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
